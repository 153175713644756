body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	background-color: #fffff5;
}

.thumbnail {
	display: inline-block;
	height: 50px;
	width: 50px;
	background-position: center;
	background-size: cover;
	margin: 5px;
}
