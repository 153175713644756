@import url(https://fonts.googleapis.com/css?family=PT+Mono|Roboto:900|Roboto+Mono|Source+Sans+Pro:300);
body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	background-color: #fffff5;
}

.thumbnail {
	display: inline-block;
	height: 50px;
	width: 50px;
	background-position: center;
	background-size: cover;
	margin: 5px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 100px;
}

.App-header {
  background-color: #3d3f43;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: "Roboto Mono", sans-serif;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  transition: height 500ms;
  width: 100vw;
}

.animated-header {
  position: absolute;
  top: calc(50vh - 106px);
  width: 100%;
  font-size: 5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.custom-select {
  text-align: left;
}

.custom-selections {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0;
  margin: 0;
}

.verb {
  color: #0de87bff;
}

.noun {
  z-index: 10;
}

.App-title {
  font-size: 5em;
}

.App-intro {
  font-size: large;
}

.App-footer {
  font-size: 12px;
}

.main-content {
  height: 100vh;
}

.blog-list-post {
  width: 80%;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.blog-post-link {
  font-family: "Roboto", sans-serif;
  text-align: left;
  text-decoration: none;
  color: #444;
}

.blog-post-description {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.blog-post-summary {
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  text-align: left;
}

.fb-icon {
  width: 50px;
  height: 50px;
}

h3 {
  color: #354399;
}

@media only screen and (max-width: 480px) {
  .App {
    text-align: left;
  }

  .App-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 50px;
    transition: height 500ms;
  }

  .App-logo {
    height: 50px;
    margin-right: 10px;
  }
}

